import { Component, ViewEncapsulation } from '@angular/core';
import { GeneralService, Names, VoteType } from 'src/app/services/general.service';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ListComponent {
    names: Names[];

    constructor(private generalService: GeneralService) {
        this.getNames();

        this.generalService.refreshList$.subscribe(x => {
            this.getNames();
        })
    }

    vote(name: string, vote: VoteType) {
        this.generalService.vote(name, vote)
            .subscribe(() => {
                this.getNames();
            });
    }

    checkVote(name: string, vote: VoteType) {
        return this.likes[name] && this.likes[name] === vote;
    }

    get likes() {
        return this.generalService.userPreferences.likes;
    }

    private getNames() {
        this.generalService.getNames()
            .subscribe(result => {
                this.names = result.sort((a,b) => b.Votes - a.Votes);
            });
    }
}
