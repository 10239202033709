import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GeneralService {
    refreshList$ = new Subject();
    userPreferences: UserPreferences;
    private serverPath: string;
    private userPreferencesKey = 'bimbo';

    constructor(private http: HttpClient, private snackBar: MatSnackBar) {
        this.serverPath = `${environment.baseUrl}/server`;

        this.userPreferences = JSON.parse(localStorage.getItem(this.userPreferencesKey)) || { preferences: false, likes: {} };
        console.log(this.serverPath);
    }

    getNames() {
        return this.http.get<Names[]>(`${this.serverPath}/getNames.php`);
    }

    addNames(name1: string, name2: string) {
        this.userPreferences.preferences = {
            firstName: name1,
            secondName: name2
        };
        this.updateLocalStorage();

        const path = (() => {
            const base = `${this.serverPath}/addNames.php`;

            if (name1 && !name2) {
                return `${base}?name1=${name1}`;
            } else if (!name1 && name2) {
                return `${base}?name2=${name2}`;
            } else {
                return `${base}?name1=${name1}&name2=${name2}`;
            }
        })();
        return this.http.get(path);
    }

    vote(name: string, vote: VoteType) {
        this.userPreferences.likes[name] = vote;
        this.updateLocalStorage();

        return this.http.get(`${this.serverPath}/${vote === 'up' ? 'likeName' : 'unlikeName'}.php?name=${name}`);
    }

    showMessage(message: string) {
        this.snackBar.open(message, null, { duration: 8000 });
    }

    private updateLocalStorage() {
        localStorage.setItem(this.userPreferencesKey, JSON.stringify(this.userPreferences));
    }
}

export type VoteType = 'up' | 'down';
export interface UserPreferences {
    preferences: { firstName: string, secondName: string };
    likes: { [key: string]: VoteType }
}

export interface Names {
    Name: string;
    Votes: number;
}