import { BreakpointObserver } from '@angular/cdk/layout';
import { StepperOrientation } from '@angular/cdk/stepper';
import { Component, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GeneralService } from './services/general.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent {
    formGroup: FormGroup;
    stepperOrientation: Observable<StepperOrientation>;

    constructor(
        breakpointObserver: BreakpointObserver,
        private generalService: GeneralService
    ) {
        this.formGroup = new FormGroup({
            phone: new FormControl(null, Validators.minLength(10)),
        });

        this.stepperOrientation = breakpointObserver.observe('(min-width: 800px)')
            .pipe(
                map(({ matches }) => matches ? 'horizontal' : 'vertical')
            );
    }

    onSubmit() { }
}
