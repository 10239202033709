import { AfterViewInit, ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GeneralService } from 'src/app/services/general.service';

@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FormComponent implements AfterViewInit {
    formGroup: FormGroup;

    constructor(private generalService: GeneralService, private cdr: ChangeDetectorRef) {
        this.formGroup = new FormGroup({
            firstName: new FormControl(null, [Validators.required, Validators.minLength(3)]),
            secondName: new FormControl(null, Validators.minLength(3))
        });
    }

    ngAfterViewInit() {
        if (this.votesCompleted) {
            this.formGroup.disable();
            this.cdr.detectChanges();
        }

        this.checkFormDisabled(this.userPreferences.preferences.firstName, this.userPreferences.preferences.secondName);
    }

    onSubmit() {
        const name1 = (() => {
            if (this.userPreferences.preferences.firstName || !this.formGroup.get('firstName').value) {
                return null;
            }

            const name = this.formGroup.get('firstName').value.toLowerCase().trim();
            return name.charAt(0).toUpperCase() + name.slice(1);
        })();

        const name2 = (() => {
            if (this.userPreferences.preferences.secondName || !this.formGroup.get('secondName').value) {
                return null;
            }

            const name = this.formGroup.get('secondName').value.toLowerCase().trim();
            return name.charAt(0).toUpperCase() + name.slice(1);
        })();

        this.generalService.addNames(name1, name2)
            .subscribe(x => {
                this.checkFormDisabled(name1, name2);

                this.generalService.refreshList$.next();
                this.generalService.showMessage('Grazie per aver contribuito! Non dimenticare di votare i nomi nella lista 😁');
            });
    }

    get userPreferences() {
        return this.generalService.userPreferences;
    }

    get votesCompleted() {
        return this.userPreferences.preferences.firstName && this.userPreferences.preferences.secondName;
    }

    private checkFormDisabled(firstName: string, secondName: string) {
        if (firstName) {
            this.formGroup.get('firstName').setValue(this.userPreferences.preferences.firstName);
            this.formGroup.get('firstName').disable();
        }

        if (secondName) {
            this.formGroup.get('secondName').setValue(this.userPreferences.preferences.secondName);
            this.formGroup.get('secondName').disable();
        }

        if (firstName && secondName) {
            this.formGroup.disable();
        }

        this.cdr.detectChanges();
    }
}
